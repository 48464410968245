import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ConfirmationService, LazyLoadEvent, MessageService,} from 'primeng/api';

import {ComboTrxType} from 'src/app/core/models/ComboTrxType';
import {OrderStatus} from 'src/app/shared/enums/OrderStatus';
import {RefundTrxPayload} from 'src/app/core/models/RefundTrxPayload';
import {RequestErrorTriggerService} from 'src/app/services/request-error-trigger.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {Transaction} from 'src/app/core/models/Transaction';
import {TransactionService} from 'src/app/services/transaction.service';
import {TransactionType} from 'src/app/shared/enums/TransactionType';
import {PaginationService} from 'src/app/services/pagination.service';
import {TransactionFeeOutput} from 'src/app/core/models/TransactionFeeOutput';
import * as FileSaver from 'file-saver';
import {TransactionOutputForExport} from "../../../core/models/TransactionOutputForExport";
import {Pageable} from "../../../core/models/Pageable";
import {Table} from "primeng/table";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dt') table: Table;
  @Input() tenantUUIDSelected;
  @Input() tenantName;
  @Input() resetTable = false;
  @Input() tenantList = [];
  loading: boolean = false;
  idStore: number = null;
  transactions: Transaction[];
  totalRecords: number;
  totalCurrentMonth: number = 0;
  trxSelected: Transaction;
  trxDialog: boolean;
  obs: Subscription[] = [];
  trxTypes: ComboTrxType[];
  statuses = OrderStatus;
  eventTable = null;
  refresh: boolean;
  pageSize: number = 50;
  showRefundDialog: boolean = false;
  isFullRefund = false;
  isPartialRefund = false;
  trxToRefund: Transaction = null;
  months: any = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
  monthsOrdered: any = [];
  monthSelected: any = null;
  monthTrxFeeList: TransactionFeeOutput[] = null;
  monthTrxList: TransactionOutputForExport[] = null;
  showSuccessRefund = false;
  disableRefundOptions: boolean = false;
  searchTerm = null;
  isSuperAdmin: boolean;

  constructor(
    private transactionSrv: TransactionService,
    private spinnerSrv: SpinnerService,
    private messageService: MessageService,
    private errorService: RequestErrorTriggerService,
    private paginationSrv: PaginationService,
    private confirmationService: ConfirmationService,
    private authSrv: AuthService
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.idStore = sessionStorage.getItem('storeId') != null && sessionStorage.getItem('storeId') != undefined ? Number(sessionStorage.getItem('storeId')) : null;
    if (this.isSuperAdmin) {
      this.tenantUUIDSelected = sessionStorage.getItem('tenant-transactions') ? sessionStorage.getItem('tenant-transactions') : this.tenantList[0].uuid;
    } else {
      this.tenantUUIDSelected = localStorage.getItem('tenant');  
    }
    this.spinnerSrv.loadSpinner.next(true);
    this.trxSelected = null;
    this.trxDialog = false;
    this.hideRefundDialog();
    this.resetValues();
    this.loadTypes();
    this.trxToRefund = null;
    this.setMonthsFromCurrentDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tenantUUIDSelected'] && changes['tenantUUIDSelected']?.previousValue != changes['tenantUUIDSelected']?.currentValue) {
      this.loadTransactions(this.eventTable, this.tenantUUIDSelected);
    }
    if (changes['resetTable'] && changes['resetTable']?.currentValue === true) {
      sessionStorage.removeItem('transactions-table');
      this.table.reset();
      this.searchTerm = null;
      this.loadTransactions(this.eventTable, this.tenantUUIDSelected);
    }
  }

  setMonthsFromCurrentDate() {
    const last2OfCurrentYear: string = new Date().getFullYear().toString().slice(-2);
    const last2OfPreviousYear: string = (new Date().getFullYear() - 1).toString().slice(-2);
    const month: number = new Date().getMonth();

    for (let i = month; i >= 0; i--) {
      this.monthsOrdered.push(
        {id: i + 1, name: "Service charge: " + this.months[i] + " " + last2OfCurrentYear}
      );
    }

    for (let i = 11; i > month; i--) {
      this.monthsOrdered.push(
        {id: i + 1, name: "Service charge: " + this.months[i] + " " + last2OfPreviousYear}
      );
    }
  }

  getYearOfMonthSelectedForExcel() {
    const year: number = new Date().getFullYear();
    const month: number = new Date().getMonth() + 1;
    return (this.monthSelected <= month) ? year : year - 1;
  }

  loadTransactions(e: LazyLoadEvent, tenantUUIDSelected: string): void {
    let pageNumber = 0;
    if (e) {
      this.eventTable = e;
      this.pageSize = e.rows;
      pageNumber = e.first / e.rows;
    }
    this.searchTerm = e.globalFilter;
    this.loading = true;
    const multiSortFields = e ? this.paginationSrv.getMultiSortObject(e) : null;
    if (this.idStore != null) {
      this.obs.push(
        this.transactionSrv
          .getStoreTransactions(this.tenantUUIDSelected, this.idStore, pageNumber, multiSortFields, this.pageSize, e.globalFilter).subscribe({
          next: res => {
            this.transactions = res.content;
            this.totalRecords = res.totalElements;
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: err => {
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    } else {
      this.obs.push(
        this.transactionSrv.getAllTransactions(tenantUUIDSelected, pageNumber, multiSortFields, this.pageSize, e.globalFilter).subscribe({
          next: (res: Pageable) => {
            this.transactions = res.content;
            this.totalRecords = res.totalElements;
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: err => {
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    }
  }

  loadTypes(): void {
    this.trxTypes = [
      {name: 'Mesa', value: TransactionType.TABLE},
      {name: 'Delivery', value: TransactionType.DELIVERY},
      {name: 'Take Away', value: TransactionType.TAKE_AWAY},
      {name: 'Reserva', value: TransactionType.BOOKING},
    ];
  }

  seeDetails(trx: Transaction): void {
    this.trxSelected = trx;
    this.trxDialog = true;
  }

  cancelTransaction(): void {
    this.spinnerSrv.loadSpinner.next(true);
    const payload: RefundTrxPayload = {
      idTrx: this.trxToRefund.id,
      idCustomer: this.trxToRefund.customerData.customerId,
      originId: this.trxToRefund.originData.originId,
      originType: this.trxToRefund.originData.type,
      isFullRefund: this.isFullRefund
    };
    this.obs.push(
      this.transactionSrv.cancelTransaction(payload, this.tenantUUIDSelected).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Transacción cancelada',
          });
          this.spinnerSrv.loadSpinner.next(false);
          this.hideRefundDialog();
          this.showSuccessRefund = true;
          this.loadTransactions(this.eventTable, this.tenantUUIDSelected);
        },
        error: err => {
          this.spinnerSrv.loadSpinner.next(false);
          if (err.status === 404 || err.status === 400) {
            this.handleErrorMessage(err.status);
          }
        }
      })
    );
  }

  hideRefundSuccessDialog() {
    this.showSuccessRefund = false;
    this.resetValues();
  }

  onRefresh(): void {
    this.loadTransactions(this.eventTable, this.tenantUUIDSelected);
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 404:
        message = 'Transacción inexistente.';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  refundTrx(trx: Transaction): void {
    if (trx.originData.ticketDelegated) {
      this.confirmationService.confirm({
        message: 'El reembolso que intenta ejecutar corresponde a un ticket transferido',
        header: '',
        icon: 'icon-warning icon-4xl icon-yellow',
        rejectIcon: 'none',
        acceptIcon: 'none',
        acceptLabel: 'Continuar',
        rejectLabel: 'Abandonar',
        key: 'confirmRefund',
        accept: () => {
          this.spinnerSrv.loadSpinner.next(true);
          this.confirmCancellation(trx);
        }
      });      
    } else {
      this.confirmCancellation(trx);
    }    
  }


  confirmCancellation(trx: Transaction): void {
    this.trxToRefund = trx;
    this.resetValues();
    if (trx.originData.type === 'ORDER') {
      this.isFullRefund = true;
      this.isPartialRefund = false;
      this.disableRefundOptions = true;
    }
    this.showRefundDialog = true;
    this.spinnerSrv.loadSpinner.next(false);
  }

  resetValues() {
    this.isFullRefund = false;
    this.isPartialRefund = true;
    this.disableRefundOptions = false;
  }

  hideDialog(): void {
    this.trxDialog = false;
  }

  hideRefundDialog(): void {
    this.showRefundDialog = false;
  }

  getType(type: String): 'ORDEN' | 'TICKET' | 'COMBO/MESA' | 'RESERVA' | 'ORIGEN' {
    if (type === 'ORDER') {
      return 'ORDEN';
    } else if (type === 'EVENT_ACCESS') {
      return 'TICKET';
    } else if (type === 'EVENT_TABLE') {
      return 'COMBO/MESA';
    } else if (type === 'BOOKING') {
      return 'RESERVA';
    } else {
      return 'ORIGEN';
    }
  }

  canRefund() {
    return this.isFullRefund || this.isPartialRefund;
  }

  checkFull(event: any) {
    this.isFullRefund = event.checked;
    if (this.isFullRefund) {
      this.isPartialRefund = false;
    }
  }

  checkPartial(event: any) {
    this.isPartialRefund = event.checked;
    if (this.isPartialRefund) {
      this.isFullRefund = false;
    }
  }

  downloadExcel() {
    if (!this.monthSelected) {
      return;
    }
    this.loading = true;
    let year = this.getYearOfMonthSelectedForExcel();
    this.spinnerSrv.loadSpinner.next(true);
    this.obs.push(
      this.transactionSrv.getTransactionsFeeByMonthAndYearAndCommerceId(this.monthSelected, year, this.idStore, this.tenantUUIDSelected).subscribe({
        next: (res: TransactionFeeOutput[]) => {
          this.monthTrxFeeList = res;
          this.exportExcel('Service_charge', this.monthTrxFeeList);
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        },
        error: err => {
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        }
      })
    );
  }

  //Export to excel functions
  downloadExcelTransactions() {
    if (!this.monthSelected) {
      return;
    }
    this.loading = true;
    let year = this.getYearOfMonthSelectedForExcel();
    this.spinnerSrv.loadSpinner.next(true);
    this.obs.push(
      this.transactionSrv.getTransactionsByMonthAndYearAndCommerceId(this.monthSelected, year, this.idStore, this.tenantUUIDSelected).subscribe({
        next: (res: TransactionOutputForExport[]) => {
          this.monthTrxList = res;
          this.exportExcel('Transacciones', this.monthTrxList);
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        },
        error: err => {
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        }
      })
    );
  }

  exportExcel(nameFile: string, list: any) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(list);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      this.saveAsExcelFile(excelBuffer, `${nameFile} ${this.tenantName}-${this.getMonthSelected().name}`);
    });
  }

  getMonthSelected() {
    return this.monthsOrdered.find(month => month.id === this.monthSelected)
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  showMotive(trx: Transaction) {
    return trx.message && !trx.message.includes('Checkout APROBADO', 0);
  }

  isUnderRevision(trx) {
    return trx.message && trx.message.includes('En Revisión', 0);
  }

  canTrxRefund(trx: Transaction) {
    let ticketResaleCanRefund = trx.originData ? trx.originData.canRefund : false;
    return trx.state === 'APPROVED' && !trx.refound && ticketResaleCanRefund;
  }

  ngOnDestroy(): void {
    this.obs.forEach((ob: Subscription) => {
      ob.unsubscribe();
    });
  }

}
